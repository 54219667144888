@import "../../../styles/base/abstracts";

.hbs-overline {
  @include baseline-text($font-weight: $fw-semibold);
}

a.hbs-overline {
  @include subtle-link($c-spot, $c-spot);
}

span.hbs-overline {
  color: $c-spot;
}
